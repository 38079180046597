import React, { useState, useEffect } from "react";
import Spinner from "../../layout/spinner/Spinner";
import Nav from "../../layout/Nav/Nav";
import API from "../../../utils/apiFunctions";
import BreadCrumbs from "../../layout/BreadCrumbs/BreadCrumbs";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F4F6F8",
    minHeight: "100vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%",
  },
}));

function SingleStudent(props) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const id = props.match.params.id;

  useEffect(() => {
    async function fetchData() {
      const student = await API.getSingleChild(id);
      setData(student.data);
    }
    fetchData();
  }, [id]);
  if (data.length < 1) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Nav active="Students" />
      <main className={classes.content}>
        <Toolbar />
        <BreadCrumbs
          crumbs={[
            {
              text: "Parents",
              link: "/parents",
              color: "inherit",
            },
            {
              text: `${data.parent.fname} ${data.parent.lname}`,
              link: `/parents/${data.parent._id}`,
              color: "inherit",
            },
            {
              text: `${data.fname} ${data.lname}`,
              link: `/students/${data._id}`,
              color: "textPrimary",
            },
          ]}
        />
        <Box
          width="auto"
          bgcolor="#FFF"
          style={{
            borderRadius: "5px",
            padding: "20px",
            margin: "10px",
          }}
        ></Box>
      </main>
    </div>
  );
}

export default SingleStudent;
