import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Card, Form, Logo } from "./LoginStyled";
import { connect } from "react-redux";
import { login } from "../../../actions/auth";
import LogoImg from "./logo.png";

//Material UI
import Alert from "../../layout/Alerts/Alert";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import PropTypes from "prop-types";
const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    color: "white",
  },
  inputContainer: {
    marginBottom: "10px",
    borderColor: "white",
  },
}));

function Login({ login, isAuthenticated }) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const { username, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  //Redirect if logged is
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="App-header" style={{ position: "relative" }}>
      <Card>
        <Logo src={LogoImg} />
        <Form onSubmit={onSubmit}>
          <CssTextField
            className={classes.inputContainer}
            variant="outlined"
            type="text"
            name="username"
            value={username}
            onChange={(e) => onChange(e)}
            placeholder="username"
            required
            InputProps={{
              className: classes.inputStyle,
            }}
            InputLabelProps={{
              className: classes.inputStyle,
            }}
          />
          <CssTextField
            type="password"
            name="password"
            className={classes.inputContainer}
            variant="outlined"
            value={password}
            onChange={(e) => onChange(e)}
            placeholder="password"
            required
            InputProps={{
              className: classes.inputStyle,
            }}
            InputLabelProps={{
              className: classes.inputStyle,
            }}
          />
          <Button
            color="primary"
            size="large"
            variant="contained"
            type="submit"
            value="Login"
            // onClick={onSubmit}
          >
            Sign In
          </Button>
        </Form>
      </Card>
      <div style={{ position: "absolue" }}>
        <Alert />
      </div>
    </div>
  );
}

login.PropTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
