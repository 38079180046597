import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { logout } from "../../../actions/auth";
import LogoImg from "../../assets/whiteLogo.png";
import { connect } from "react-redux";

//Material UI
import { deepPurple } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";

import DashboardIcon from "@material-ui/icons/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import FaceIcon from "@material-ui/icons/Face";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import SettingsIcon from "@material-ui/icons/Settings";
import Avatar from "@material-ui/core/Avatar";

import { Typography } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  userArea: {
    display: "flex",
    justifyContent: "center",
    padding: "10px",
    flexDirection: "column",
    alignItems: "center",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: deepPurple[500],
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  userName: {
    fontWeight: 600,
    marginTop: "10px",
  },
  avatar: {
    backgroundColor: deepPurple[500],
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
  logo: {
    flexGrow: 1,
  },
}));

const StyledListItem = withStyles({
  root: {
    backgroundColor: "white",
    "&$selected": {
      backgroundColor: "white",
      color: deepPurple[500],
    },
  },
  selected: {},
})(ListItem);

function Nav({ user, logout, active }) {
  const classes = useStyles();
  let activeItem = {
    Dashboard: false,
    Parents: false,
    Students: false,
    Classes: false,
    Settings: false,
  };
  activeItem[active] = true;

  const NavItems = [
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: (
        <DashboardIcon
          style={activeItem.Dashboard ? { color: deepPurple[500] } : {}}
        />
      ),
    },
    {
      name: "Parents",
      link: "/parents",
      icon: (
        <SupervisorAccountIcon
          style={activeItem.Parents ? { color: deepPurple[500] } : {}}
        />
      ),
    },
    {
      name: "Students",
      link: "/students",
      icon: (
        <FaceIcon
          style={activeItem.Students ? { color: deepPurple[500] } : {}}
        />
      ),
    },
    {
      name: "Classes",
      link: "/classes",
      icon: (
        <SentimentVerySatisfiedIcon
          style={activeItem.Classes ? { color: deepPurple[500] } : {}}
        />
      ),
    },
    {
      name: "Settings",
      link: "/settings",
      icon: (
        <SettingsIcon
          style={activeItem.Settings ? { color: deepPurple[500] } : {}}
        />
      ),
    },
  ];

  if (user) {
    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <div className={classes.logo}>
              <img alt="studio butler logo" src={LogoImg} />
            </div>
            <Button onClick={logout} color="inherit">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <div className={classes.userArea}>
              <Avatar
                className={classes.avatar}
              >{`${user.fname[0]}${user.lname[0]}`}</Avatar>
              <Typography
                className={classes.userName}
              >{`${user.fname} ${user.lname}`}</Typography>
              <Typography>{user.studioName}</Typography>
            </div>
            <Divider />

            <List>
              {NavItems.map((item) => (
                <StyledListItem
                  button
                  key={item.name}
                  component={Link}
                  to={item.link}
                  selected={activeItem[item.name]}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </StyledListItem>
              ))}
            </List>
            {/* <Divider /> */}
            {/* <List>
              {["All mail", "Trash", "Spam"].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List> */}
          </div>
        </Drawer>
      </>
    );
  } else {
    return <h1>Loading...</h1>;
  }
}

Nav.propTypes = {
  studio: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  logout: PropTypes.func.isRequired,
});

export default connect(mapStateToProps, { logout })(Nav);
