import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../layout/spinner/Spinner";
import Nav from "../../layout/Nav/Nav";
import { logout } from "../../../actions/auth";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import API from "../../../utils/apiFunctions";

import "../Dashboard/Dashboard.css";
import DataTable from "./DataTable";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F4F6F8",
    minHeight: "100vh",
  },
  upperControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  subControls: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    height: 40,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%",
  },
}));

function Parents() {
  const classes = useStyles();

  const [data, setData] = useState([]);

  useEffect(() => {
    try {
      async function fetchData() {
        // const parents = await axios.get("http://localhost:5000/api/parents/children");
        const parents = await API.getAllParentsWithChildren();

        setData(parents.data);
      }

      fetchData();
    } catch (err) {
      console.log("err", err);
    }
  }, []);
  if (!data) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Nav active="Parents" />
      <main className={classes.content}>
        <Toolbar />
        <div className={classes.upperControls}>
          <div className={classes.subControls}>
            <Typography variant="h5">All Parents</Typography>
            <TextField
              style={{ marginLeft: "20px" }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                className: classes.input,
              }}
              id="outlined-basic"
              label="Quick Search"
              variant="outlined"
            />
          </div>
          <Button
            component={Link}
            to={`/parents/new`}
            size="small"
            variant="contained"
            className={classes.button}
            startIcon={<AddIcon />}
          >
            New Parent
          </Button>
        </div>
        <Box
          width="auto"
          bgcolor="#FFF"
          style={{
            borderRadius: "5px",
            margin: "10px",
          }}
        >
          <DataTable data={data} />
        </Box>
      </main>
    </div>
  );
}

logout.PropTypes = {
  logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(Parents);
