import axios from "axios";

const BASEURL = process.env.REACT_APP_BASEURL;

export default {
  //Auth
  login: async function (body, config) {
    return await axios.post(`${BASEURL}/api/auth/`, body, config);
  },

  loadUser: async function () {
    return await axios.get(`${BASEURL}/api/auth/`);
  },

  //Parents

  getAllParentsWithChildren: async function () {
    return await axios.get(`${BASEURL}/api/parents/children/`);
  },

  createNewParent: async function (body, config) {
    return await axios.post(`${BASEURL}/api/parents/`, body, config);
  },

  getSingleParent: async function (id) {
    return await axios.get(`${BASEURL}/api/parents/${id}`);
  },

  deleteOneParent: async function (id) {
    return await axios.delete(`${BASEURL}/api/parents/${id}`);
  },

  //Students

  getAllChildren: async function () {
    return await axios.get(`${BASEURL}/api/children/`);
  },

  getSingleChild: async function (id) {
    return await axios.get(`${BASEURL}/api/children/${id}`);
  },
};
