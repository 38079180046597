import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { deepPurple } from "@material-ui/core/colors";

const AntTabs = withStyles({
  root: {
    border: "1px solid #e8e8e8",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
  },
  indicator: {
    backgroundColor: deepPurple[500],
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: deepPurple[500],
      opacity: 1,
    },
    "&$selected": {
      color: deepPurple[500],
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: deepPurple[500],
    },
  },
  selected: {},
}))((props) => <Tab {...props} />);

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: "98%",
    // border: "1px solid #e8e8e8",
    boxSizing: "border-box",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function DataTable({ data }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [filData, setFilData] = useState(data);

  useEffect(() => {
    setFilData(data);
  }, [data]);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0: {
        setFilData(data);
        break;
      }
      case 1: {
        setFilData(data.filter((parent) => parent.status === "Lead"));
        break;
      }
      case 2: {
        setFilData(data.filter((parent) => parent.status === "Active"));
        break;
      }
      case 3: {
        setFilData(data.filter((parent) => parent.status === "Inactive"));
        break;
      }
      default: {
        setFilData(data);
        break;
      }
    }
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {console.log(filData)}
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
          <AntTab label="ALL" />
          <AntTab label="LEADS" />
          <AntTab label="ACTIVE" />
          <AntTab label="INACTIVE" />
        </AntTabs>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell># Classes Registered</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filData.map((child) => (
                <TableRow key={child._id}>
                  <TableCell>
                    <Avatar
                      className={classes.purple}
                    >{`${child.fname[0].toUpperCase()}${child.lname[0].toUpperCase()}`}</Avatar>
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {child.fname} {child.lname}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {child.status}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {child.classes.length}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      aria-label="expand row"
                      component={Link}
                      to={`/students/${child._id}`}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
