import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 300,
    maxWidth: 350,
  },
  heading: {
    fontWeight: 500,
  },
  con: {
    maxWidth: 350,
  },
});

export default function InfoTable({ parent }) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.con} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {Object.keys(parent).map(function (key) {
            return (
              <TableRow key={parent[key]}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.heading}
                >
                  {key}
                </TableCell>
                <TableCell align="right">{parent[key]}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
