export default {
  calculateAge: function (date) {
    const birthday = new Date(date);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  },

  isNewlyCreated(date) {
    const datecreated = new Date(date).getTime();
    const now = new Date().getTime();
    return now - datecreated < 345600000;
  },
};
