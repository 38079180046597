import React from "react";
import { Link } from "react-router-dom";

import TableHead from "@material-ui/core/TableHead";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CallIcon from "@material-ui/icons/Call";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import {
  deepPurple,
  green,
  deepOrange,
  pink,
  teal,
} from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import utils from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  lead: {
    color: "white",
    backgroundColor: green[400],
  },
  inactive: {
    color: theme.palette.getContrastText(deepOrange[800]),
    backgroundColor: deepOrange[800],
  },
  enrolled: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  trial: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  new: {
    color: theme.palette.getContrastText(teal[500]),
    backgroundColor: teal[500],
  },
}));

export default function ExpandableRow({ parent }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow key={parent._id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {utils.isNewlyCreated(parent.dateCreated) ? (
            <Chip size="small" label="New" className={classes.new} />
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          <Avatar
            className={classes.purple}
          >{`${parent.fname[0].toUpperCase()}${parent.lname[0].toUpperCase()}`}</Avatar>
        </TableCell>
        <TableCell component="th" scope="row">
          {parent.fname} {parent.lname}
        </TableCell>
        <TableCell component="th" scope="row">
          <Chip
            size="small"
            label={parent.status}
            className={classes[parent.status.toLowerCase()]}
          />
        </TableCell>
        <TableCell>
          {parent.children.length >= 2
            ? `${parent.children[0].fname} ${parent.children[0].lname} + ${
                parent.children.length - 1
              }`
            : parent.children.length === 1
            ? `${parent.children[0].fname} ${parent.children[0].lname}`
            : ""}
        </TableCell>

        <TableCell> {parent.email}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row">
            <CallIcon />
          </IconButton>
          <IconButton
            aria-label="expand row"
            component={Link}
            to={{
              pathname: `/parents/${parent._id}`,
              state: {
                parent: parent,
              },
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Children
              </Typography>
              <Table size="medium" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Age</TableCell>
                    <TableCell align="center"># Classes Registered</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parent.children.map((child) => (
                    <TableRow key={child._id}>
                      <TableCell component="th" scope="row">
                        {`${child.fname} ${child.lname}`}
                      </TableCell>
                      <TableCell>{utils.calculateAge(child.dob)}</TableCell>
                      <TableCell align="center">
                        {child.classes.length}
                      </TableCell>
                      <TableCell align="center">{child.status}</TableCell>
                      <TableCell padding="none" size="small" align="right">
                        <Button
                          component={Link}
                          to={`/students/${child._id}`}
                          color="secondary"
                          size="small"
                          variant="contained"
                          startIcon={<VisibilityIcon />}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
