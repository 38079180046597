import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Students from "../pages/Students/Students";
import SingleStudent from "../pages/Students/SingleStudent";
import Parents from "../pages/Parents/Parents";
import AddParent from "../pages/Parents/AddParent";
import SingleParent from "../pages/Parents/SingleParent";
import Settings from "../pages/Settings/Settings";
import Classes from "../pages/Classes/Classes";

import Login from "../pages/Auth/Login";
import PrivateRoute from "./PrivateRoute";

const Routes = () => {
  return (
    <section className="container">
      <Switch>
        <Route exact path="/login" component={Login} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/students" component={Students} />
        <PrivateRoute exact path="/students/:id" component={SingleStudent} />
        <PrivateRoute exact path="/parents" component={Parents} />
        <PrivateRoute exact path="/parents/new" component={AddParent} />
        <PrivateRoute exact path="/parents/:id" component={SingleParent} />
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/classes" component={Classes} />
      </Switch>
    </section>
  );
};

export default Routes;
