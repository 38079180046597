import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = ({ alerts }) =>
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map((alert) => (
    <MuiAlert key={alert.id} variant="filled" severity={alert.alertType}>
      {console.log(alert)}
      {alert.msg}
    </MuiAlert>
  ));

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
