import React, { useState, useEffect } from "react";
import Spinner from "../../layout/spinner/Spinner";
import Nav from "../../layout/Nav/Nav";
import { Redirect } from "react-router-dom";
import API from "../../../utils/apiFunctions";

import "../Dashboard/Dashboard.css";
import InfoTable from "./InfoTable";
// import BreadCrumbs from "../../layout/BreadCrumbs/BreadCrumbs";

//Material UI
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F4F6F8",
    minHeight: "100vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%",
  },
}));

export default function SingleParent(props) {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [redirect, setRedirect] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const { parent } = props.location.state || [];

    setData(parent);
    async function fetchData() {
      const parents = await API.getSingleParent(props.match.params.id);
      setData(parents.data);
    }
    if (!data.length) {
      fetchData();
    }
  }, [props]);

  const handleDeleteParent = async () => {
    const deletedParent = await API.deleteOneParent(data._id);
    console.log(deletedParent);
    if (deletedParent.status === 200) {
      setRedirect(true);
    }
  };

  if (redirect) {
    return <Redirect to={`/parents/`} />;
  }

  if (!data) {
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          All Parents
        </Typography>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Nav active="parents" />
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h5">
          {data.fname} {data.lname}
        </Typography>
        <Box
          width="auto"
          bgcolor="#FFF"
          style={{
            borderRadius: "10px",
            padding: "20px",
            margin: "10px",
          }}
        >
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Parent Information" {...a11yProps(0)} />
              <Tab label="Item Two" {...a11yProps(1)} />
              <Tab label="Item Three" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <InfoTable
              parent={{
                Name: `${data.fname} ${data.lname}`,
                Email: data.email,
                Status: data.status,
                Phone: data.phone,
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            Item Two
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Button
              onClick={handleDeleteParent}
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
            >
              Delete Parent
            </Button>
          </TabPanel>
        </Box>
      </main>
    </div>
  );
}
