import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

export default function BreadCrumbs({ crumbs }) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs.map((crumb) => (
        <Link
          key={crumb.text}
          component={RouterLink}
          to={crumb.link}
          color={crumb.color}
        >
          {crumb.text}
        </Link>
      ))}
    </Breadcrumbs>
  );
}
