import React, { useState } from "react";
import Nav from "../../layout/Nav/Nav";
import BreadCrumbs from "../../layout/BreadCrumbs/BreadCrumbs";
import TextMastCustom from "../../layout/MaskedInput/TextMaskCustom";
import API from "../../../utils/apiFunctions";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";

// import InputMask from "react-input-mask";

import "../Dashboard/Dashboard.css";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Toolbar from "@material-ui/core/Toolbar";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    background: "#F4F6F8",
    minHeight: "100vh",
  },
  content: {
    flexGrow: 1,
    maxWidth: "70%",
    margin: "0 auto",
    padding: theme.spacing(3),
    height: "100%",
  },
  input: {
    width: "100%",
  },
  formHeading: {
    margin: "10px 0",
  },
}));

export default function AddParent() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    pfname: "",
    plname: "",
    sfname: "",
    slname: "",
    dob: new Date(),
    phone: "",
    email: "",
    status: "Lead",
  });
  const [redirect, setRedirect] = useState(false);
  const [newParentID, setNewParentID] = useState(null);

  const handleDateChange = (date) => {
    setFormData({ ...formData, dob: date });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const {
    pfname,
    plname,
    sfname,
    slname,
    dob,
    email,
    phone,
    status,
  } = formData;
  let body = JSON.stringify({
    pfname,
    plname,
    sfname,
    slname,
    dob,
    email,
    phone,
    status,
  });
  const config = {
    headers: { "Content-Type": "application/json" },
  };
  const handleSubmit = async () => {
    try {
      // const res = await axios.post("http://localhost:5000/api/parents/",body,config);
      const res = await API.createNewParent(body, config);
      console.log(res);
      if (res.data.parentUpdate._id) {
        setNewParentID(res.data.parentUpdate._id);
        setRedirect(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  if (redirect) {
    return <Redirect to={`/parents/${newParentID}`} />;
  }

  return (
    <div className={classes.root}>
      <Nav active="Parents" />
      <main className={classes.content}>
        <Toolbar />
        <Typography variant="h5">New Parent</Typography>
        <BreadCrumbs
          crumbs={[
            { text: "Parents", link: "/parents", color: "inherit" },
            {
              text: "New Parent",
              link: "/parents/new",
              color: "textPrimary",
            },
          ]}
        />
        <Box
          width="auto"
          bgcolor="#FFF"
          style={{
            borderRadius: "10px",
            padding: "20px",
            margin: "10px",
          }}
        >
          <form>
            <Typography className={classes.formHeading} variant="h5">
              Parent Information
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-required"
                  label="First Name"
                  value={formData.pfname}
                  name="pfname"
                  variant="outlined"
                  onChange={handleChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-required"
                  label="Last Name"
                  value={formData.plname}
                  name="plname"
                  variant="outlined"
                  onChange={handleChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="formatted-text-mask-input"
                  variant="outlined"
                  label="Phone"
                  className={classes.input}
                  InputProps={{
                    inputComponent: TextMastCustom,
                    value: formData.phone,
                    onChange: handleChange,
                    name: "phone",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-required"
                  className={classes.input}
                  label="Email"
                  value={formData.email}
                  name="email"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.input} variant="outlined">
                  <InputLabel id="demo-simple-select-outlined-label">
                    Status
                  </InputLabel>
                  <Select
                    value={FormData.status}
                    onChange={handleChange}
                    className={classes.input}
                    label="Status"
                    name="status"
                  >
                    <MenuItem value={"Lead"}>Lead</MenuItem>
                    <MenuItem value={"Trial"}>Trial</MenuItem>
                    <MenuItem value={"Enrolled"}>Enrolled</MenuItem>
                    <MenuItem value={"Inactive"}>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              style={{ marginTop: "30px" }}
              className={classes.formHeading}
              variant="h5"
            >
              Student Information
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-required"
                  label="First Name"
                  value={formData.sfname}
                  name="sfname"
                  variant="outlined"
                  onChange={handleChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="outlined-required"
                  label="Last Name"
                  value={formData.slname}
                  name="slname"
                  variant="outlined"
                  onChange={handleChange}
                  className={classes.input}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    name="dob"
                    id="date-picker-inline"
                    label="Date of birth"
                    value={formData.dob}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </main>
    </div>
  );
}
