import React, { useState, useEffect } from "react";
import Spinner from "../../layout/spinner/Spinner";
import Nav from "../../layout/Nav/Nav";
import axios from "axios";
import PropTypes from "prop-types";

import "../Dashboard/Dashboard.css";
// import DataTable from "./DataTable";

//Material UI
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#F4F6F8",
    minHeight: "100vh",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    height: "100%",
  },
}));

function Dashboard() {
  const classes = useStyles();

  const [data, setData] = useState([]);

  // useEffect(() => {
  //   try {
  //     async function fetchData() {
  //       const parents = await axios.get(
  //         "http://localhost:5000/api/parents/children"
  //       );
  //       setData(parents.data);
  //     }

  //     fetchData();
  //   } catch (err) {
  //     console.log("err", err);
  //   }
  // }, []);
  if (!data) {
    return (
      <div>
        <Typography variant="h4" gutterBottom>
          All Students
        </Typography>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Nav active="Dashboard" />
      <main className={classes.content}>
        <Toolbar />
        <Box
          width="auto"
          bgcolor="#FFF"
          style={{
            borderRadius: "5px",
            padding: "20px",
            margin: "10px",
          }}
        ></Box>
      </main>
    </div>
  );
}

export default Dashboard;
